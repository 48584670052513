import axios from 'axios';
export const apiNotification = (id) => {
    // console.log('apiNotification')
    return axios.get(`/srm/api-rest/notification/${id}`);
};
export const apiNotificationRead = (id) => {
    // console.log('apiNotificationRead')
    return axios.patch(`/srm/api-rest/notification/read/${id}`);
};
export const apiNotificationDelete = (id) => {
    // console.log('apiNotificationDelete')
    return axios.delete(`/srm/api-rest/notification/delete/${id}`);
};
export const apiNotificationsList = () => {
    // console.log('apiNotificationsList')
    return axios.get('/srm/api-rest/notification/');
};
